import { UseMutationResult, UseQueryResult, keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import {
  createClientEndpoint,
  getDeleteClientEndpoint,
  getGetAllClientsEndpoint,
  getGetClientByEndpoint,
  getUpdateClientEndpoint,
} from "../endpoints";
import { httpClient } from "../network";
import { toast } from "react-toastify";
import {
  ICreateClientRequest,
  ICreateClientResponse,
  IDeleteClientResponse,
  IGetAllClientsRequest,
  IGetAllClientsResponse,
  IGetClientByRequest,
  IGetClientByResponse,
  IUpdateClientRequest,
  IUpdateClientResponse,
} from "../../../types";

export const useCreateClientService = (): UseMutationResult<ICreateClientResponse, unknown, ICreateClientRequest> => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (createClientPayload: ICreateClientRequest) => {
      const response: AxiosResponse<ICreateClientResponse> = await httpClient.post(createClientEndpoint(), createClientPayload);
      return response.data;
    },
    onSuccess: () => {
      toast.success("Klijent je uspješno kreiran");
      queryClient.invalidateQueries({ queryKey: ["getAllClients"] });
    },
    onError: () => {

      toast.error("Klijent nije uspješno kreiran")
    },
  });

  return mutation;
};

export const useUpdateClientService = (): UseMutationResult<IUpdateClientResponse, unknown, IUpdateClientRequest> => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (updateClientPayload: IUpdateClientRequest) => {
      const response: AxiosResponse<IUpdateClientResponse> = await httpClient.put(
        getUpdateClientEndpoint(updateClientPayload.clientId),
        updateClientPayload.updateClientData
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Klijent je uspješno ažuriran");
      queryClient.invalidateQueries({ queryKey: ["getClientBy"] });
    },
    onError: () => {
      toast.error("Klijent nije uspješno ažuriran")
    },
  });

  return mutation;
};
export const useDeleteClientService = (): UseMutationResult<IDeleteClientResponse, unknown, string> => {
  const mutation = useMutation({
    mutationFn: async (clientId: string) => {
      const response: AxiosResponse<IDeleteClientResponse> = await httpClient.delete(getDeleteClientEndpoint(clientId));
      return response.data;
    },
    onSuccess: () => {
      localStorage.setItem("deletionSuccess", "true");
      if (typeof window !== "undefined") 
        (window.location as Location).href = "/dashboard/clients";
    },
    onError: () => {
      toast.error("Klijent nije uspješno obrisan")
    },
  });
  return mutation;
};

export const useGetAllClientsService = (getAllClientsQueries: IGetAllClientsRequest): UseQueryResult<IGetAllClientsResponse, unknown> => {
  const query = useQuery({
    queryKey: ["getAllClients", getAllClientsQueries],
    queryFn: async () => {
      const response: AxiosResponse<IGetAllClientsResponse> = await httpClient.get(getGetAllClientsEndpoint(getAllClientsQueries));
      return response.data;
    },
    retry: 0,
    placeholderData: keepPreviousData,
  });
  return query;
};

export const useGetClientByService = (getClientByQueries: IGetClientByRequest): UseQueryResult<IGetClientByResponse, unknown> => {
  const query = useQuery({
    queryKey: ["getClientBy", getClientByQueries],
    queryFn: async () => {
      const response: AxiosResponse<IGetClientByResponse> = await httpClient.get(getGetClientByEndpoint(getClientByQueries));
      return response.data;
    },
  });
  return query;
};
