export const Messages = {
  auth: {
    WRONG_PASSWORD_FORMAT: 'Pogrešan format šifre.',
    WRONG_EMAIL_FORMAT: 'Pogrešan format e-mail adrese.',
    THROTTLER_ERROR:
      'Previše zahtjeva poslato. Molimo vas sačekajte nekoliko trenutaka prije slanja novih zahtijeva.',
    RESET_PASSWORD_EMAIL_SENT:
      'E-mail sa uputstvima za resetovanje šifre je uspješno poslat.',
    RESET_PASSWORD_EMAIL_RESENT:
      'E-mail sa uputstvima za resetovanje šifre je uspješno ponovo poslat.',
    SUCCESSFULLY_CHANGED_PASSWORD: 'Uspješno ste promijenili vašu šifru.',
    AUTH_01: 'Autentifikacija nije uspjela zbog neispravnih podataka.',
    AUTH_02:
      'Autentifikacija nije uspjela jer korisnički račaun nije verifikovan.',
    AUTH_03: 'Token nije validan.',
    AUTH_04:
      'Autentifikacijski kod je još uvijek validan. Pogledajte vaš e-mail ili kreirajte novi kod za 15 minuta.',
    AUTH_05:
      'Autentikaciji kod je istekao, URL više nije aktivan. Kreirajte novi kod.',
    AUTH_06: 'Korisnik nije prijavljen.',
    AUTH_07: 'Korisnik povezan sa tokenom ne postoji.',
    AUTH_08: 'Korisnik sa ovim e-mailom već postoji.',

    SUCCESSFULLY_LOGGED_IN: (fullName: string) =>
      `Dobrodošli nazad ${fullName}`,
  },
};
