import { IGetAllReservationsRequest, IGetAvailableAppointmentsRequest, IGetReservationByRequest } from "../../../types";

const API_PREFIX = "/reservations";
export const getGetAllReservationsEndpoint = (getAllReservationsQueries: IGetAllReservationsRequest) => {
  const conditions = getAllReservationsQueries.conditions;

  const queryString = `${API_PREFIX}?from=${conditions?.from}&to=${conditions?.to}`;
  return queryString;
};

export const getGetReservationByEndpoint = (getReservationBy: IGetReservationByRequest) =>
  `${API_PREFIX}/getBy?prop=${getReservationBy.prop}&value=${getReservationBy.value}`;
export const getGetAvailableAppointmentsEndpoint = (getAvailableAppointmentsQueries: IGetAvailableAppointmentsRequest) =>
  `${API_PREFIX}/getAvailableAppointments`;
export const getCreateReservationEndpoint = () => `${API_PREFIX}`;

export const editReservationEndpoint = (id: string) => `${API_PREFIX}/${id}`;
