const API_PREFIX = '/auth';

export const getLoginEndpoint = () => `${API_PREFIX}/sign-in`;
export const getLogoutEndpoint = () => `${API_PREFIX}/sign-out`;
export const getForgotPasswordEndpoint = () => `${API_PREFIX}/forgot-password`;
export const getResetPasswordEndpoint = () => `${API_PREFIX}/reset-password`;
export const getRefreshTokenEndpoint = () => `${API_PREFIX}/refresh-token`;
export const getResendResetPasswordEmailEndpoint = () =>
  `${API_PREFIX}/resend-reset-password-email`;
export const getInviteUserEndpoint = () => `${API_PREFIX}/invite-user`;
export const getSetPasswordEndpoint = () => `${API_PREFIX}/set-password`;
