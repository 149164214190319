import { Messages } from './messages';

type MessageType = typeof Messages;

type ErrorResponseType = {
  message: string;
  errorCode: keyof MessageType[keyof MessageType];
  statusCode: string | number;
};

export const useMessage = ({
  isError,
  key,
  errorResponse,
  messagePayload,
  property,
}: {
  isError?: boolean;
  errorResponse?: ErrorResponseType;
  messagePayload?: string;
  property?: keyof MessageType[keyof MessageType];
  key: keyof MessageType;
}) => {
  let message = '';

  if (isError) {
    if (errorResponse) {
      const errorMessage = Messages[key]?.[errorResponse.errorCode];
      if (errorMessage) {
        if (typeof errorMessage !== 'string' && messagePayload) {
          message = errorMessage(messagePayload);
        } else {
          message = typeof errorMessage === 'string' ? errorMessage : '';
        }
      } else {
        if (key === 'auth' && errorResponse?.message.includes('password')) {
          message = Messages[key]['WRONG_PASSWORD_FORMAT'];
        } else if (
          key === 'auth' &&
          errorResponse?.message.includes('e-mail')
        ) {
          message = Messages[key]['WRONG_EMAIL_FORMAT'];
        }
      }
    }
  } else {
    if (property) {
      const messageTemp = Messages[key]?.[property];

      if (messageTemp) {
        if (typeof messageTemp !== 'string' && messagePayload) {
          message = messageTemp(messagePayload);
        } else {
          message = typeof messageTemp === 'string' ? messageTemp : '';
        }
      }
    }
  }

  return message;
};
