import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import {
  getForgotPasswordEndpoint,
  getInviteUserEndpoint,
  getLoginEndpoint,
  getLogoutEndpoint,
  getRefreshTokenEndpoint,
  getResendResetPasswordEmailEndpoint,
  getResetPasswordEndpoint,
} from "../endpoints";
import { httpClient } from "../network";
import {
  IForgotPasswordRequest,
  IForgotPasswordResponse,
  IInviteUserRequest,
  IInviteUserResponse,
  ILoginRequest,
  ILoginResponse,
  ILogoutResponse,
  IRefreshTokenRequest,
  IRefreshTokenResponse,
  IResendResetPasswordEmailRequest,
  IResendResetPasswordEmailResponse,
  IResetPasswordRequest,
  IResetPasswordResponse,
  ISetPasswordRequest,
  ISetPasswordResponse,
} from "../../../types";
import { toast } from "react-toastify";

export const useLoginService = (): UseMutationResult<ILoginResponse, unknown, ILoginRequest> => {
  const mutation = useMutation({
    mutationFn: async (loginData: ILoginRequest) => {
      const response: AxiosResponse<ILoginResponse> = await httpClient.post(getLoginEndpoint(), loginData);
      return response.data;
    },
    onSuccess: () => {
      if (typeof window !== "undefined")
        window.location.href = '/dashboard/appointments'
    }
  });

  return mutation;
};
export const useLogoutService = (): UseMutationResult<ILogoutResponse, unknown, void> => {
  const mutation = useMutation({
    mutationFn: async () => {
      const response: AxiosResponse<ILogoutResponse> = await httpClient.get(getLogoutEndpoint());
      if(typeof window === 'undefined') {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('staff');
      }
      return response.data;
    },
    onSuccess: (res) => {
      if (typeof window !== "undefined") 
        window.location.assign('/auth/login')
    },
    onError: (err) => {
      if (typeof window !== "undefined") 
        window.location.assign('/auth/login')
    }
  });
  return mutation;
};
export const useForgotPasswordService = (): UseMutationResult<IForgotPasswordResponse, unknown, IForgotPasswordRequest> => {
  const mutation = useMutation({
    mutationFn: async (forgottPasswordData: IForgotPasswordRequest) => {
      const response: AxiosResponse<IForgotPasswordResponse> = await httpClient.post(getForgotPasswordEndpoint(), forgottPasswordData);
      return response.data;
    },
    onSuccess: () => {
      toast.success("Email za postavljanje lozinke je poslan");
    },
    onError: () => {
      toast.error("Email za postavljanje lozinke nije poslan");
    }
  });
  return mutation;
};
export const useResetPasswordService = (): UseMutationResult<IResetPasswordResponse, unknown, IResetPasswordRequest> => {
  const mutation = useMutation({
    mutationFn: async (resetPasswordData: IResetPasswordRequest) => {
      const response: AxiosResponse<IResetPasswordResponse> = await httpClient.post(getResetPasswordEndpoint(), resetPasswordData);
      return response.data;
    },
  });
  return mutation;
};
export const useRefreshTokenService = (): UseMutationResult<IRefreshTokenResponse, unknown, IRefreshTokenRequest> => {
  const mutation = useMutation({
    mutationFn: async (refreshTokenData: IRefreshTokenRequest) => {
      const response: AxiosResponse<IRefreshTokenResponse> = await httpClient.post(getRefreshTokenEndpoint(), refreshTokenData);
      if(typeof window !== 'undefined') {
        localStorage.setItem('accessToken', response.data.data.accessToken);
        localStorage.setItem('refreshToken', response.data.data.refreshToken);
      }
      return response.data;
    },
    onSuccess: (response) => {
      if (typeof window !== "undefined") 
        window.location.assign('/auth/appointments')
    }
  });
  return mutation;
};
export const useResendResetPasswordEmailService = (): UseMutationResult<
  IResendResetPasswordEmailResponse,
  unknown,
  IResendResetPasswordEmailRequest
> => {
  const mutation = useMutation({
    mutationFn: async (resendResetPasswordData: IResendResetPasswordEmailRequest) => {
      const response: AxiosResponse<IResendResetPasswordEmailResponse> = await httpClient.post(
        getResendResetPasswordEmailEndpoint(),
        resendResetPasswordData
      );
      return response.data;
    },
  });
  return mutation;
};
export const useInvitUserService = (): UseMutationResult<IInviteUserResponse, unknown, IInviteUserRequest> => {
  const mutation = useMutation({
    mutationFn: async (inviteUserData: IInviteUserRequest) => {
      const response: AxiosResponse<IInviteUserResponse> = await httpClient.post(getInviteUserEndpoint(), inviteUserData);
      return response.data;
    },
  });
  return mutation;
};
export const useSetPassword = (): UseMutationResult<ISetPasswordResponse, unknown, ISetPasswordRequest> => {
  const mutation = useMutation({
    mutationFn: async (setPasswordData: ISetPasswordRequest) => {
      const response: AxiosResponse<ISetPasswordResponse> = await httpClient.post(getInviteUserEndpoint(), setPasswordData);
      return response.data;
    },
  });
  return mutation;
};
