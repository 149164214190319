import { IGetAllClientsRequest, IGetClientByRequest } from "../../../types";

const API_PREFIX = "/clients";

export const createClientEndpoint = () => `${API_PREFIX}`;
export const getGetAllClientsEndpoint = (queries: IGetAllClientsRequest) =>
  `${API_PREFIX}${queries.pageSize && queries.page ? `?page=${queries.page}&pageSize=${queries.pageSize}` : ""}`;
export const getGetClientByEndpoint = (getClientByQueries: IGetClientByRequest) =>
  `${API_PREFIX}/getBy?prop=${getClientByQueries.prop}&value=${getClientByQueries.value}`;
export const getUpdateClientEndpoint = (clientId: string) => `${API_PREFIX}/${clientId}`;
export const getDeleteClientEndpoint = (clientId: string) => `${API_PREFIX}/${clientId}`;
