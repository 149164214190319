import { IGetAllUsersRequest, IGetUserByRequest } from "../../../types";

const API_PREFIX = "/staffs";

export const createUserEndpoint = () => `${API_PREFIX}`;
export const createWorkTimeEndpoint = (employeeId: string) => `${API_PREFIX}/${employeeId}/changeWorkingHours`;
export const getGetAllUsersEndpoint = (queries: IGetAllUsersRequest) =>
  `${API_PREFIX}${queries.pageSize && queries.page ? `?page=${queries.page}&pageSize=${queries.pageSize}` : ""}`;
export const getGetUserByEndpoint = (getUserByQueries: IGetUserByRequest) =>
  `${API_PREFIX}/getBy?prop=${getUserByQueries.prop}&value=${getUserByQueries.value}`;
export const getUpdateUserEndpoint = (userId: string) => `${API_PREFIX}/${userId}`;
export const getDeleteUserEndpoint = (userId: string) => `${API_PREFIX}/${userId}`;
export const addFreeDaysEndpoint = (userId: string) => `${API_PREFIX}/${userId}/addFreeDays`;
export const deleteFreeDaysEndpoint = (freeDaysId: string) => `${API_PREFIX}/freeDays/${freeDaysId}`
export const checkWorkingTimeEndpoint = (
  staffId: string,
  day: number,
  startTime: string,
  isAvailable: boolean,
  startBreak: string,
  endBreak: string,
) => 
  `${API_PREFIX}/checkWorkingTime?staffId=${staffId}&day=${day}&startTime=${startTime}&isAvailable=${isAvailable}&startBreak=${startBreak}&endBreak=${endBreak}`
