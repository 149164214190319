import {
    keepPreviousData,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult
} from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import {IEquipment, IEquipmentResponse} from "../../../types";
import { httpClient } from '../network'
import {createEquipmentEndpoint, getEquipmentEndpoint} from "../endpoints/equipmentEndpoint";

export const useCreateEquipmentService =
    (): UseMutationResult<IEquipmentResponse, unknown, IEquipment> => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (createEquipmentPayload: IEquipment) => {
            try {
                const response: AxiosResponse<IEquipmentResponse> =
                    await httpClient.post(createEquipmentEndpoint(), createEquipmentPayload);
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        onSuccess: () => {
            toast.success("Oprema je uspešno kreirana");
            queryClient.invalidateQueries({ queryKey: ["getAllEquipments"] });
        },
        onError: (error: unknown) => {
            toast.error(`Oprema nije uspešno kreirana: ${(error as Error).message}`);
        },
    });

    return mutation;
};

export const useGetAllEquipmentService = (getAllEquipmentQueries: any): UseQueryResult<IEquipmentResponse, unknown> => {
    const query = useQuery({
        queryKey: ["getAllEquipment", getAllEquipmentQueries],
        queryFn: async () => {
            const response: AxiosResponse<IEquipmentResponse> = await httpClient.get(getEquipmentEndpoint());
            return response.data;
        },
        retry: 0,
        placeholderData: keepPreviousData,
    });
    return query;
};
