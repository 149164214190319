import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";

const conf: AxiosRequestConfig = {
  baseURL: process.env.NEXT_PUBLIC_API_URI, 
  withCredentials: true, 
};

const httpClient: AxiosInstance = axios.create(conf);

httpClient.interceptors.request.use(
  (request) => {
    if(typeof window !== 'undefined') {
      const subdomain = window.location.host.split('.')[0];
      request.headers['x-schema'] = subdomain;
    }
    if (
      process.env.NEXT_PUBLIC_NODE_ENV === 'development' &&
      typeof window !== 'undefined'
    ) {
      request.headers['Authorization'] = JSON.stringify({
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
        staff: encodeURIComponent(localStorage.getItem('staff') as string)
      })
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);



const refreshTokenRequest = async () => {
  const response = await httpClient.get("/auth/refresh", {
    headers: {
      'Authorization': JSON.stringify({
        accessToken: typeof window !== 'undefined' ?
          localStorage.getItem('accessToken')
          :
          '',
        refreshToken: typeof window !== 'undefined' ?
        localStorage.getItem('refreshToken')
        :
        '',
      })
    }
  }); 
  if(typeof window !== 'undefined') {
    localStorage.setItem('accessToken', response.data.data.accessToken);
    localStorage.setItem('refreshToken', response.data.data.refreshToken);
  }
  return response?.data;
};

let isRefreshing = false; 
let failedQueue: any[] = []; 

const processQueue = (error: AxiosError | null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });
  failedQueue = [];
};

httpClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response; 
  },
  async (error: AxiosError) => {
    const originalRequest = error.config;
    //@ts-ignore
    if (error.response && error.response.status === 406) {
      if (typeof window !== "undefined")  {
        const response = await httpClient.get('/auth/sign-out');
        localStorage.clear();
        window.location.href = '/auth/login';

      }
      return Promise.reject(error);
    }
    //@ts-ignore
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      //@ts-ignore
      originalRequest._retry = true; 
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then((token) => {
          //@ts-ignore
          originalRequest.headers['Authorization'] = 'Bearer ' + token; 
          //@ts-ignore
          return httpClient(originalRequest);
        });
      }
      //@ts-ignore
      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const newToken = await refreshTokenRequest();
        httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + newToken; 
        processQueue(null); 
        //@ts-ignore
        return httpClient(originalRequest);
      } catch (refreshError) {
        //@ts-ignore
        processQueue(refreshError); 
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }
    return Promise.reject(error);
  }
);

export { httpClient, conf };
